import logo from "./logo-black.svg";
import logoWhite from "./logo-white.svg";
import webinarImage from "./DOLCE_BLUE_JASMINE_1920x900_GRIFFE_HP.jpg";
import webinarImage2 from "./DG-light-blue.jpeg";
import "./App.css";
import { useMemo, useEffect, useState } from "react";
import serialize from "form-serialize";
import _ from "lodash";

const TAPPE = [
    { label: "9 Novembre - Catania - Hotel Baia Verde 20.30 - 23.00", value: "9 novembre" },
    { label: "14 Novembre - Napoli - Grand Hotel Vesuvio 20.30 - 23.00", value: "14 novembre" },
    { label: "20 Novembre - Bologna - I Portici Hotel 20.30 - 23.00", value: "20 novembre" },
    { label: "22 Novembre - Torino - Hotel Principi di Piemonte - 20.30", value: "22 novembre" },
];
const WEBINAR = [
    { label: "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_23 Luglio ore 20.00", value: "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_23 Luglio ore 20.00", thankyou: "23 Luglio ore 20.00<br/>SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA" },
    { label: "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_24 Luglio ore 20.00", value: "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_24 Luglio ore 20.00", thankyou: "24 Luglio ore 20.00<br/>SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA" }
];

function App() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const emails23 = ["i.vranescu@becreatives.com", 
        "cristina.adami0098@gmail.com",
        "agnolettosonia@libero.it",
        "alacqua.ombretta@gmail.com",
        "emiliab72@gmail.com",
        "amirado.a@gmail.com",
        "martinaangiolelli@gmail.com",
        "elena.baldrighi@hotmail.it",
        "monica.barbieri2@gmail.com",
        "info@profumeriabandini.com",
        "anna.bavuso@gmail.com",
        "angbel@hotmail.it",
        "myriem.benali1@gmail.com",
        "susan.berlusconi@gmail.com",
        "ileana.86@live.it",
        "antobonanno98@gmail.com",
        "borsanicristiana@yahoo.it",
        "lessyyea@gmail.com",
        "nicobrambi63@gmail.com",
        "gaiabrioschi@icloud.com",
        "lucreziabruno95@gmail.com",
        "miki7763@hotmail.it",
        "anita.buruiana@gmail.com",
        "caggionimaya@gmail.com",
        "greta9027@gmail.com",
        "viviana.canesi@gmail.com",
        "canzoneri.daniela@gmail.com",
        "carpagnanov@gmail.com",
        "violacaruso01@gmail.com",
        "greta.cascella@gmail.com",
        "patrizia.castrovinci@libero.it",
        "ledacavalmoretti@lively.it",
        "ladydeb75@yahoo.it",
        "ros.cec1109@gmail.com",
        "doriana.cecconello@icloud.com",
        "cecere.jenna@gmail.com",
        "ceredaaale01@gmail.com",
        "samycesa@virgilio.it",
        "maricili@hotmal.it",
        "stefaniacojocnean97@gmail.com",
        "denisecorrente02@gmail.com",
        "monicacrema@hotmail.it",
        "mag1578@sephora.it",
        "ddaloia@libero.it",
        "loredanadg4@gmail.com",
        "p.jeunesse@libero.it",
        "luise.dec@gmail.com",
        "giulia.defalco96@gmail.com",
        "mariannadefazio71@gmail.com",
        "bea.deluca39@gmail.com",
        "vale85.delorenzo@gmail.com",
        "eleonoradg.181002@gmail.com",
        "rosa.dinapoli72@gmail.com",
        "annadistefanomilano@gmail.com",
        "annadistefanomilano@gmail.com",
        "el.mabrouk.wafaa@gmail.com",
        "jadirajess@gmail.com",
        "fede.rica2700@gmail.com",
        "esposito.lucia1999@gmail.com",
        "farinella.nicole2004@gmail.com",
        "mikifavullo@gmail.com",
        "federiconoemi2211@gmail.com",
        "aryfer88@yahoo.it",
        "babi.figini@gmail.com",
        "fabiolafiore.01@gmail.com",
        "info@proffrancavillasrl.191.it",
        "frullaaurora@gmail.com",
        "vivianafugagnollo@yahoo.it",
        "fusarinelida@gmail.com",
        "aleksandragalan01@gmail.com",
        "marziaenicky@yahoo.it",
        "ariannagaravaglia@lively.it",
        "simygary@libero.it",
        "francescacamillamarina@gmail.com",
        "alessiagianniello@hotmail.it",
        "alice.giorgio2@gmail.com",
        "cla.graziani2@gmail.com",
        "parfumery@hotmail.it",
        "grecchieleonora1989@gmail.com",
        "silviagreco255@gmail.com",
        "martinaagreguoldoo@gmail.com",
        "francesca.grisoni@gmail.com",
        "marikaguastella@gmail.com",
        "basma.hamsi@dolcegabbana.it",
        "m.hernandez@mazzolari-milano.it",
        "aleiac77@libero.it",
        "annaluisa87@hotmail.it",
        "impellizzerierika@gmail.com",
        "defeudis75@gmail.com",
        "antonina.ivashchuk@dolcegabbana.it",
        "wujialing12161996@gmail.com",
        "jitescudenisa@gmail.com",
        "mag1578@sephora.it",
        "anto.landro86@gmail.com",
        "luciana.lizzadro@libero.it",
        "vallerie.llanes@yahoo.com",
        "rosalinda.lodi@yahoo.it",
        "giulialoiodice44@gmail.com",
        "gidifema@gmail.com",
        "maglionemelissa22@gmail.com",
        "malagoligreta.malagoli@gmail.com",
        "martman@hotmail.it",
        "manfrinclaudia99@gmail.com",
        "giulia.ma99@gmail.com",
        "erni1967@tiscali.it",
        "marrasfrancesca3@gmail.com",
        "genesismartba@hotmail.com",
        "pietro.mattarelli@icloud.com",
        "giulianamelcore@gmail.com",
        "mag1578@sephora.it",
        "marija.mihajlovic@dolcegabbana.it",
        "milanimatilde99@gmail.com",
        "michelamimmo@msn.com",
        "minutoliviviana@gmail.com",
        "cristinamiron9@gmail.com",
        "mag1578@sephora.it",
        "nicoletta.monti.3@gmail.com",
        "alessandromorazzoni@lively.it",
        "mottamary1990@gmail.com",
        "officialraluca@yahoo.com",
        "mutopiera95@gmail.com",
        "cristina.natale1972@gmail.com",
        "monica.orlando70@libero.it",
        "nicolev.ortega97@gmail.com",
        "sue_ellen_p@msn.com",
        "panettadebora@virgilio.it",
        "stefy.paraschiv@gmail.com",
        "elisa.pashkja@hotmail.com",
        "elenapasini98@gmail.com",
        "r.passeri@douglas.it",
        "sabrinapeccerillo@gmail.com",
        "giadina.piccenna@gmail.com",
        "mag1578@sephora.it",
        "georgianapintilie1996@gmail.com",
        "mag1578@sephora.it",
        "giusy.principato68@gmail.com",
        "luisapruneri@gmail.com",
        "anna.maria99@tiscali.it",
        "berti0804@gmail.com",
        "eleonoraradini@lively.it",
        "barbara.raga.br@gmail.com",
        "f.rizzotti@pinalli.it",
        "ilaravera@hotmail.it",
        "designer@thefirst.it",
        "arianna.regalli@gmail.com",
        "silviareggi83@gmail.com",
        "angelica.riccardolavoro@gmail.com",
        "riccolucrezia97@gmail.com",
        "ripamontivanessa@gmail.com",
        "f.rizzotti@pinalli.it",
        "f.rizzotti@pinalli.it",
        "nicolerojatti@gmail.com",
        "elianevanessa@hotmail.it",
        "mag1578@sephora.it",
        "giadarusmini@gmail.com",
        "alessiarvsso@icloud.com",
        "r.saccinto@gmail.com",
        "cettisole2@gmail.com",
        "salafranca61@gmail.com",
        "salamone002@gmail.com",
        "danielabeek@yahoo.it",
        "esaranora@gmail.com",
        "scalese.chiara@gmail.com",
        "giusy16b@yahoo.it",
        "lorenasima1806@gmail.com",
        "vanessasorrentino@gmail.com",
        "silvanaspadaro.ss@gmail.com",
        "p.jeunesse@libero.it",
        "chiaratiozzo1@gmail.com",
        "mtocco@sephora.it",
        "tomeorosanna@gmail.com",
        "n.torciero@mazzolari-milano.it",
        "antonietta.trdanari@gmail.com",
        "sonia.valore@libero.it",
        "ventimigliacristian@gmail.com",
        "lisa.verrastro.78@gmail.com",
        "nicolevialetto@icloud.com",
        "alessiavilla.stud@gmail.com",
        "laura.villa.66@icloud.com",
        "michela-violatto@virgilio.it",
        "stefyemax1981@libero.it",
        "info@profumeriabandini.com",
        "mag1578@sephora.it",
        "xiaoyingyang29@icloud.com",
        "mag1578@sephora.it",
        "eva.ivi@hotmail.it",
        "zupanicarianna@gmail.com"];
    const emails24 = ["sonia.abbattista@yahoo.it",
        "moniaabdelraouf@gmail.com",
        "ealbertini@sephora.it",
        "chappi@libero.it",
        "sarettaa2000@icloud.com",
        "sara.aquilino24@gmail.com",
        "niky.cocci@gmail.com",
        "fedebaj@libero.it",
        "elena.baldrighi@hotmail.it",
        "vansimoro@gmail.com",
        "marinella.jeunesse@gmail.com",
        "rbellavia@sephora.it",
        "lauraben989@gmail.com",
        "luigi.bobbio53@gmail.com",
        "gborsani67@gmail.com",
        "91.lavale@gmail.com",
        "brigliasimo@gmail.com",
        "la.mamy1963@gmail.com",
        "cadili.valentina@gmail.com",
        "rovenacala9@gmail.com",
        "oksana.canaku@gmail.com",
        "angelitacastelforte@yahoo.it",
        "catyvery87@gmail.com",
        "cecottiloredana@gmail.com",
        "ceidebora@hotmail.it",
        "valentina.cerri.81@gmail.com",
        "andreachiappa98@libero.it",
        "daniecolombo@gmail.com",
        "beatrice.cominardi@tim.it",
        "patriziacontini@icloud.com",
        "ornellacorrenti53@gmail.com",
        "nancyalejandracruz@icloud.com",
        "luna.curro@yahoo.com",
        "emanuele.dalessandro01@gmail.com",
        "danieledandra@hotmail.it",
        "silvia@gepro.it",
        "alessiamaria.davide94@gmail.com",
        "dedigentiana@gmail.com",
        "auroradelpercio@gmail.com",
        "alessiadipalma@gmail.com",
        "lollo.dipietro@gmail.com",
        "missluvigarland@icloud.com",
        "nickelback1@hotmail.it",
        "jessicafalz@hotmail.it",
        "veronicafazio91@icloud.com",
        "simonafazzese04@gmail.com",
        "giuliagraceferraiolo@gmail.com",
        "ferrari.marta91@gmail.com",
        "alexashton1978@gmail.com",
        "gallisofia24@gmail.com",
        "irene.gallinari@gmail.com",
        "giulia.ghislandi10@gmail.com",
        "simagholamy@yahoo.com",
        "serena.giaccone@icloud.com",
        "vickyc.gonzalez@gmail.com",
        "bgrisetti@sehora.it",
        "monica.iannello76@gmail.com",
        "illianomyriam@gmail.com",
        "izzoivan636@gmail.com",
        "sandralaguardia@libero.it",
        "saralamaddalena04@gmail.com",
        "giorgiofox@tiscalinet.it",
        "michi.lazzaroni@gmail.com",
        "alicelimongi@hotmail.it",
        "mlivraghi@sephora.it",
        "mlorato@sephora.it",
        "lara.lorenzini@mabelprofumerie.it",
        "michelina2000@live.it",
        "claudia.malquiquispe@gmail.com",
        "sonovalentina1997@gmail.com",
        "lucamarazzi.ek@gmail.com",
        "portafoglio21@yahoo.it",
        "mastromatteograzia@gmail.com",
        "yvonn92@icloud.com",
        "ali.meneghel@gmail.com",
        "francescamerillo51@gmail.com",
        "stefaniam.36@gmail.com",
        "tiziana.morea71@gmail.com",
        "grgmsc01@gmail.com",
        "merillymuniz@gmail.com",
        "martamartenji@gmail.com",
        "namiraxnasir@gmail.com",
        "camilla.negroni99@gmail.com",
        "martinanova96@gmail.com",
        "sara.olly24@icloud.com",
        "info.ivanornago@gmail.com",
        "malak2003.ouairem@gmail.com",
        "fraancescapaaggi@gmail.com",
        "sonia@gepro.it",
        "paoluccigiada2002@gmail.com",
        "blanko.sp@libero.it",
        "elisa.game77@gmail.com",
        "proftiso@yahoo.it",
        "julia.petraccaro@gmail.com",
        "chi2000_it@yahoo.it",
        "rita.pigliafreddo@libero.it",
        "info@profumeriabandini.com",
        "giorgiapistritto11@gmail.com",
        "mina.pizzo@hotmail.it",
        "viviana1.pomari@gmail.com",
        "p.alexa2312@gmail.com",
        "alice.raineri27@gmail.com",
        "ennireale1@gmail.com",
        "paolorei_mua@yahoo.com",
        "g.rocchetta@douglas.it",
        "viviana.rota85@libero.it",
        "valesabei@tim.it",
        "g.savelli@douglas.it",
        "angelasavino809@gmail.com",
        "ramona.lomoriello@gmail.com",
        "cristinasciortino2003@gmail.com",
        "alice.scuderi2003@gmail.com",
        "valbona.seferi@hotmail.it",
        "martinaseva@gmail.com",
        "elenasfargeri@gmail.com",
        "jeanniesibella@icloud.com",
        "sofiaa1970@icloud.com",
        "giuliasilvestri2000@gmail.con",
        "niclasolano@hotmail.it",
        "giulia94ct@gmail.com",
        "emyspano75@gmail.com",
        "eleonora.speranza@gmail.com",
        "giulia18121999@gmail.com",
        "elvirastancich@gmail.com",
        "tatiana.stigliano@hotmail.it",
        "linatiranno@icloud.com",
        "valentinatrp@gmail.com",
        "trivignodesire17@hotmail.com",
        "monicausai1974@gmail.com",
        "giuliaventola@icloud.com",
        "giorgiofox@tiscalinet.it",
        "francescazaniniconsulting@gmail.com",
        "xingz2414@gmail.com",
        "rorigna67@gmail.com"];

    const onSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const body = serialize(form, { hash: true });

        const formData = new FormData();
        for (const name in body) {
            formData.append(name, body[name]);
        }

        if (formData.get("webinar") == "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_23 Luglio ore 20.00"
            && !emails23.includes(formData.get("email").toLowerCase())) {
            setIsErrored(true);
            setIsSubmitted(true);
            return;
        }
        if (formData.get("webinar") == "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_24 Luglio ore 20.00"
            && !emails24.includes(formData.get("email").toLowerCase())) {
            setIsErrored(true);
            setIsSubmitted(true);
            return;
        }

        formData.append("newsletter", "false");

        setIsSubmitting(true);
        const response = await fetch(
            "https://wordpress-1002388-4728144.cloudwaysapps.com/wp-json/contact-form-7/v1/contact-forms/5/feedback",
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            setIsSubmitting(false);
        } else {
            const data = await response.json();
            // if (data.status === "validation_failed") {
            //     const tappaError = data.invalid_fields.find((item) => item.field === "webinar");

            //     if (tappaError) {
            //         setIsErrored(true);
            //     }
            // }

            setIsSubmitting(false);
            setIsSubmitted(true);

            // const webinar = WEBINAR.find((item) => item.value === body.webinar);
            // setWebinarSelected(webinar.thankyou);

            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <div>
            <header className="App-header items-start md:items-center">
                <img src={logo} alt="logo" className="px-4 max-w-[196px] md:max-w-[362px]" />
            </header>

            <div className="container">
                {isSubmitted ? (
                    <>
                        {!isErrored ? (
                            <>
                                <h2 className="text-2xl font-bold py-8 mt-8 text-center">Grazie per aver confermato la tua prenotazione</h2>
                            </>
                        ) : (
                            <>
                                <h2 className="text-2xl font-bold py-8 mt-8 text-center">
                                    ERRORE
                                </h2>
                                <p className="text-center">
                                    Hai selezionato la data sbagliata. Conferma solo se ci sarai nella data precedentemente scelta.

                                    <a href="#" className="mt-16 block" onClick={() => { setIsErrored(false); setIsSubmitted(false) }}>&larr; Torna indietro</a>
                                </p>
                            </>
                        )}
                    </>
                ) : isSubmitting ? (
                    <div className="py-8 mt-8 text-center text-2xl">
                        <svg
                            className="animate-spin mx-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                        >
                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                        </svg>
                    </div>
                ) : (
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form-group">
                            <label>NOME *</label>
                            <input type="text" name="nome" placeholder="IL TUO NOME" required />
                        </div>
                        <div className="form-group">
                            <label>COGNOME *</label>
                            <input type="text" name="cognome" placeholder="IL TUO COGNOME" required />
                        </div>
                        <div className="form-group">
                            <label>NUMERO DI TELEFONO</label>
                            <input type="tel" name="tel" placeholder="IL TUO NUMERO DI TELEFONO" />
                        </div>
                        <div className="form-group">
                            <label>E-MAIL *</label>
                            <input type="email" name="email" placeholder="LA TUA E-MAIL" required />
                        </div>
                        <h2 className="text-lg">CONFERMA PRESENZA</h2>
                        <div className="form-group">
                            <label>SELEZIONA LA DATA *</label>
                            <div className="relative">
                                <select name="webinar" required>
                                    <option value="">Data evento</option>
                                    {WEBINAR.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="mb-16 checkbox-container mt-4">
                            <label className="block mb-2 text-[14px]">
                                <input type="checkbox" name="profiling" value="yes" defaultChecked={false} required />{" "}
                                Ho preso visione sull’
                                <a
                                    href="/privacy-policy.pdf"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="text-underline"
                                >
                                    informativa privacy
                                </a>
                            </label>
                        </div>
                        <div className="mt-4 mb-12">
                            <button className="uppercase bg-black hover:bg-grey text-white w-full md:w-[150px] h-[40px] mt-4 mb-8">
                                Invia
                            </button>
                        </div>
                    </form>
                )}
            </div>

            <footer className="bg-black pt-16 pb-16 text-center text-white text-[8px]">
                <img src={logoWhite} className="mx-auto mb-8" alt="logo" />
                Site – managed by The Level S.r.l – Copyright © Dolce & Gabbana S.r.l. 2023 – All rights reserved – any
                reproduction of the contents is strictly forbidden
            </footer>

            {/* Version: 0.1 */}
        </div>
    );
}

export default App;
